@media only screen and (min-width: 751px) {
    .spacer {
      height: 9.1796875vh;
      position: relative;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 90.82%;  
      background: #1C1C1C;
      min-height: 5.875rem;
      width: auto;
    }  
}

  @media only screen and (max-width: 750px) {
    .spacer {
        z-index: -1;
        height: 9.1796875vh;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 3.846vw;
        width: auto;
        position: relative;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 90.82%;  
    
        background: #1C1C1C;
        min-height: 5.875rem;
    }  
}
