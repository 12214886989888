.container{
    display: flex;
    position: relative;
    width: 24%;
    overflow: hidden;
    aspect-ratio: 9/12;
    background-color: #1c1c1c;
    object-fit: cover;
}

.container:hover .overlayDesc {
    opacity: 1;
  }
.container:hover .overlayAuthor {
    opacity: 1;
  }

.overlayDesc{
    position: absolute;
    display: flex;
    width: 100%;
    height: 5%;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .2s ease;
    background-color: rgba(51, 51, 51, 0.8);
}
.overlayAuthor{
    position: absolute;
    display: flex;
    width: 60%;
    height: 6%;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: .2s ease;
    background-color: rgba(51, 51, 51, 0.7);
}

.desc {
    width: 100%;
    height: auto;
    font-family: 'ownersregular';
    overflow: visible;
    text-align: center;
    letter-spacing: .05vw;
    color: #DDDACA;
    font-size: 20px;
    font-size: clamp(0.75rem, 1.474vw + -0.833rem, 3.125rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

img {
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
}

@media only screen and (max-width: 750px) {
    .container{
        display: flex;
        position: relative;
        width: 100%;
        overflow: hidden;
        aspect-ratio: 9/12;
        background-color: white;
        object-fit: cover;
    }
}