@media only screen and (min-width: 751px) {
    nav {
        z-index: 5;
        height: 9.1796875vh;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: .69dvw;

        width: auto;
        position: fixed;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 90.82%;  
    
        background: #1C1C1C;

        min-height: 5.875rem;
    }
    a.active {
        background-color: #1C1C1C;
    }    
}

@media only screen and (max-width: 750px) {
    nav {
        z-index: 5;
        height: 9.1796875vh;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 3.846vw;
        width: auto;
    
        position: fixed;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 90.82%;  
    
        background: #1C1C1C;
        min-height: 5.875rem;
    }  
    .dropdown {
        z-index: 10;
        display: grid;
        width: auto;
        max-height: 1000px;
        position: fixed;
        left: 0%;
        right: 0%;
        opacity: 100%;
        background: #1C1C1C;
        visibility: visible;
        transition: max-height .2s, opacity .2s, visibility .2s ease;

    }
    .dropdown > a + a {
        margin-top: 0;
        border-top: 1px solid #DDDACA;
    }
    a.active {
        background-color: #1C1C1C;
    }       
    .hidden {
        z-index: 10;
        display: grid;
        opacity: 0%;
        left: 0%;
        right: 0%;
        max-height: 0px;
        visibility: hidden;
        position: fixed;
        transition: max-height .2s, opacity .2s, visibility .2s ease;
    }
}