@media only screen and (min-width: 751px) {
    iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        border: none;
        background-color: black;
    }

    .container {
        display: flex;
        justify-content: space-evenly;
        align-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }

    .video {
        position: relative;
        width: 49%;
        aspect-ratio: 16/9;
        background-color: black;
        margin-bottom: 1%;
    }
}

@media only screen and (max-width: 750px) {
    iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        border: none;
        background-color: black;
    }

    .container {
        display: flex;
        justify-content: space-evenly;
        align-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }

    .video {
        position: relative;
        width: 100%;
        aspect-ratio: 16/9;
        background-color: black;
        margin-bottom: 1%;
    }
}