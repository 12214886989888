.container {
  position: relative;
}

#photo{
  font-family: "ownersregular";  
  font-size: 29.1px;
  color: #DDDACA; 
  letter-spacing: .2dvw;
  font-weight: 100;
  margin-left: 2dvw;
  display: inline;
}

.blink {
  animation: blinker 1s linear infinite;
  font-family: "ownersregular";  
  font-size: 29.1px;
  color: #DDDACA; 
  letter-spacing: .2dvw;
  font-weight: 100;
  display: inline;
  line-height: 2;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.gallery{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  column-gap: 1px;
  row-gap: 4vh;
}
