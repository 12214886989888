.grow { transition: all .1s ease-in-out; }
.grow:hover { transform: scale(1.1); }
.spin { animation: rotate360 .5s cubic-bezier(1,.01,0,.97) 1; }
@keyframes rotate360 { to { transform: rotate(360deg); } }
@keyframes drop-in { 
    from {top: -200%;}
    to {top: 109%} 
}
@keyframes drop-out { 
    from {top: 109%;}
    to {top: -200%} 
}

@media only screen and (min-width: 751px) {
    p {
        margin-left: 0;
    }
    a:visited {
        text-decoration: none;
    }

    a:link {
        text-decoration: none;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #DDDACA;
    }
    .moon {
        text-align: center; 
        margin: auto;  
        width: 5dvh;
        display: flex;
    }
    .moon > svg {
        width: 100%;
        margin: auto;
    }
    .buttons { 
        font-family: 'ownersregular'; 
        color: #DDDACA; 
        text-align: center; 
        margin: auto; 
        font-size: 28px;
        font-size: clamp(10px, 2dvw, 28px);
        letter-spacing: 3px;
        max-height: 94px;
    }
    .nyte { grid-area: 1 / 3 / 2 / 4; width: 18.6vw; height: 7.2265625vh; display: flex; margin: auto; min-width: 20vw;}
    .nyte > svg {width: 70%;}

}

@media only screen and (max-width: 750px) {
    p {
        margin-left: 0;
    }
    a:visited {
        text-decoration: none;
    }

    a:link {
        text-decoration: none;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #DDDACA;
    }

    .moon {
        text-align: center; 
        margin: auto;  
        width: 10dvw;
        display: flex;
        min-width: 45px;
    }
    .moon > svg {
        width: 100%;
        margin: auto;
    }
    .buttons { 
        font-family: 'ownersregular'; 
        color: #DDDACA; 
        text-align: center; 
        margin: auto; 
        font-size: 28px;
        font-size: clamp(10px, 2dvw, 28px);
        letter-spacing: 3px;
        max-height: 94px;
    }
    .nyte { grid-area: 1 / 2 / 2 / 3; width: 30vw; min-width: 150px; display: block; margin: auto; z-index: 99; background: #1C1C1C}
    .burger { grid-area: 1 / 3 / 2 / 4; position: relative; display: inline-block; margin: auto; z-index: 99; background: #1C1C1C}
    svg { display: block; margin: auto; }
}