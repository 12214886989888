@media only screen and (min-width: 751px) {
  .mission{
      font-family: "ownersregular";  
      font-size: clamp(1.819rem, 0.326vw + 1.666rem, 2.188rem);
      color: #DDDACA; 
      letter-spacing: .2dvw;
      font-weight: 100;
      margin-left: 2dvw;
      display: inline;
  }

  .blink {
    animation: blinker 1s linear infinite;
    font-family: "ownersregular";  
    font-size: clamp(1.819rem, 0.326vw + 1.666rem, 2.188rem);
    color: #DDDACA; 
    letter-spacing: .2dvw;
    font-weight: 100;
    display: inline;
    line-height: 2;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }


  .name {
    font-family: "ownersmedium";  
    letter-spacing: .2dvw;
    font-size: 20px;
    color: #DDDACA; 
    margin-bottom: 0;
  }

  .job {
    font-family: "ownersregular";  
    letter-spacing: .2dvw;
    font-size: 20px;
    color: #DDDACA; 
    margin-top: 0;
  }

  .container {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  p.desc {
    font-family: "ownersregular";  
    font-size: clamp(0.938rem, 0.939vw + 0.497rem, 1.5em);
    color: #DDDACA; 
    margin-left: 2dvw;
    letter-spacing: .2dvw;
    font-weight: 100;
    display: block;
    text-align: left;
  }

  .desc {
    font-family: "ownersregular";  
    font-size: clamp(0.938rem, 0.939vw + 0.497rem, 2rem);
    color: #DDDACA; 
    margin: auto;
    letter-spacing: .2dvw;
    font-weight: 100;
    display: inline;
    text-align: left;
  }

  .profcontainer {
      width: 31%;
      height: fit-content;
      object-fit: cover;
      margin-top: 2dvh;
      margin-bottom: 2dvh;
  }

  .propic {
    background-color: #1c1c1c;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 4/5;
  }

  .bio {
    font-family: "ownersregular";  
    font-size: clamp(0.938rem, 0.497vw + 0.704rem, 1.5rem);
    color: #DDDACA; 
    margin: auto;
    letter-spacing: .2dvw;
    font-weight: 100;
    display: block;
    text-align: left;
  }
}
@media only screen and (max-width: 750px) {
    .mission{
      font-family: "ownersregular";  
      font-size: clamp(1.819rem, 0.326vw + 1.666rem, 2.188rem);
      color: #DDDACA; 
      letter-spacing: .2dvw;
      font-weight: 100;
      margin-left: 2dvw;
      display: inline;
  }

  .blink {
    animation: blinker 1s linear infinite;
    font-family: "ownersregular";  
    font-size: clamp(1.819rem, 0.326vw + 1.666rem, 2.188rem);
    color: #DDDACA; 
    letter-spacing: .2dvw;
    font-weight: 100;
    display: inline;
    line-height: 2;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }


  .name {
    font-family: "ownersmedium";  
    letter-spacing: .2dvw;
    font-size: 20px;
    color: #DDDACA; 
    margin-bottom: 0;
    margin: 10px;
  }

  .job {
    font-family: "ownersregular";  
    letter-spacing: .2dvw;
    font-size: 20px;
    color: #DDDACA; 
    margin-top: 0;
    margin: 10px;
  }

  .container {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  p.desc {
    font-family: "ownersregular";  
    font-size: clamp(0.938rem, 0.939vw + 0.497rem, 1.5em);
    color: #DDDACA; 
    margin-left: 2dvw;
    letter-spacing: .2dvw;
    font-weight: 100;
    display: block;
    text-align: left;
  }

  .desc {
    font-family: "ownersregular";  
    font-size: clamp(0.938rem, 0.939vw + 0.497rem, 2rem);
    color: #DDDACA; 
    margin: auto;
    letter-spacing: .2dvw;
    font-weight: 100;
    display: inline;
    text-align: left;
  }

  .profcontainer {
      width: 100%;
      height: fit-content;
      object-fit: cover;
      margin-top: 2dvh;
      margin-bottom: 2dvh;
  }

  .propic {
    background-color: #1c1c1c;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 4/5;
  }

  .bio {
    font-family: "ownersregular";  
    font-size: clamp(0.938rem, 0.497vw + 0.704rem, 1.5rem);
    color: #DDDACA; 
    margin: auto;
    letter-spacing: .2dvw;
    font-weight: 100;
    display: block;
    text-align: left;
    margin: 10px;
  }
}