.contact{
    font-family: "ownersregular";  
    font-size: clamp(1.819rem, 0.326vw + 1.666rem, 2.188rem);
    color: #DDDACA; 
    letter-spacing: .2dvw;
    font-weight: 100;
    margin-left: 2dvw;
    display: inline;
}
.bg {
    overflow: hidden;
    background-image: url("./components/resources/images/contact.jpg");
    position: relative;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    width: auto;
    height: calc(100vh - 5.875rem);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.overlay{
    position: absolute;
    overflow: hidden;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    background-color: rgba(28,28,28, .86);
  }

.blink {
    animation: blinker 1s linear infinite;
    font-family: "ownersregular";  
    font-size: clamp(1.819rem, 0.326vw + 1.666rem, 2.188rem);
    color: #DDDACA; 
    letter-spacing: .2dvw;
    font-weight: 100;
    display: inline;
    line-height: 2;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
}

.container {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

p.desc {
    font-family: "ownersregular";  
    font-size: clamp(1.819rem, 0.326vw + 1.666rem, 2.188rem);
    color: #DDDACA; 
    letter-spacing: .2dvw;
    font-weight: 100;
    display: block;
    text-align: left;
}
p.info > a {
    display: inline;
}
p.info {
    font-family: "ownersregular";  
    font-size: clamp(1.25rem, 0.718vw + 0.913rem, 2.063rem);
    color: #DDDACA; 

    letter-spacing: .2dvw;
    font-weight: 100;
    display: block;
    text-align: left;
}
p.book{
    font-family: "ownersregular";  
    font-size: clamp(1.819rem, 0.326vw + 1.666rem, 2.188rem);
    color: #DDDACA; 
    padding-top: 1dvh;
    letter-spacing: .2dvw;
    font-weight: 100;
    display: block;
    text-align: left;
}

button{
    font-family: "ownersregular";
    font-size: clamp(1.25rem, 0.718vw + 0.913rem, 2.063rem);
    color: #DDDACA; 
    background-color: #303030;
    border-width: 0;
}
button:hover {
    background-color: #393939;
}