@font-face {
    font-family: 'ownersmedium';
    src: url('owners-medium-webfont.woff2') format('woff2'),
         url('owners-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ownersregular';
    src: url('owners-regular-webfont.woff2') format('woff2'),
         url('owners-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}