@media only screen and (min-width: 751px) {
  body{
    background: #1C1C1C;
    background-size: cover;
    background-position: center center;
    margin: 0 0 0 0;
  }

  .introcontainer {
    position: relative;
    width: auto;
    margin: 0 0 0 0;
    height: 100dvh;
    background-color: #1C1C1C;
  }

  .preview {
    position: absolute;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    background: #dddace;
    background-size: cover;
    background-position: center center;
  }

  .overlay{
    position:absolute;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    height: 100dvh;
    background-color: rgba(28,28,28, .86);
  }

  .intro{
    position: relative;
    padding-top: 7lvh;
    font-family: 'ownersmedium'; 
    color: #DDDACA; 
    height: 100%;
    margin: 0 10vw 0 10vw; 
    font-size: 6.75rem;
    font-size: clamp( 95px, 8vw, 400px );
    line-height: .85;
    letter-spacing: 0.015em;
  }

  @media (max-aspect-ratio: 2/3) {
    .intro{
      position: relative;
      padding-top: 7lvh;
      font-family: 'ownersmedium'; 
      color: #DDDACA; 
      margin: 0 10vw 0 10vw; 
      font-size: 6.75rem;
      font-size: clamp( 95px, 8vw, 400px );
      line-height: 12dvh;
      letter-spacing: 0.015em;
      font-weight: 500;
    }
  }

  .more {
    margin-top: 8dvh;
    position: absolute;
    font-family: "ownersregular"; 
    color: #DDDACA; 
    font-size: 22.55px;
    font-size: clamp(1.9rem, 2.368vw + -0.485rem, 5.409rem);
    letter-spacing: .3dvw;
    left: 80dvw;
    top: 70dvh;
  }

  .contact {
    font-family: "ownersregular"; 
    color: #DDDACA; 
    font-size: 22.55px;
    font-size: clamp(0.625rem, 2.368vw + -0.485rem, 1.409rem);
    letter-spacing: .2dvw;
  }

  .container {
    overflow: hidden;
    position: relative;
    height: 72dvh;
    background-color: #1C1C1C;
    display: flex;
    justify-content: space-between;
    gap: 2vw;
    margin: auto;
    top: 2vw;
  }

  img {
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: cover;
    width: 100%;
  }

  .previewvid{
    position: relative;
    background: #dddace;
    background-size: cover;
    background-position: center center;
    height: 80vh;
    margin: -5vh 0 0 0;
    z-index: 0;
  }

  video{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .footer {
    text-align: center;
    display: flex;
    align-items:center;
    justify-content:center;
    position: relative;
    max-width: 100dvw;
    height: 15dvh;
    background-color: #1C1C1C;
    margin: auto;
  }
}



@media only screen and (max-width: 750px) {
  body{
    background: #1C1C1C;
    background-size: cover;
    background-position: center center;
    height: 100dvh;
    margin: 0 0 0 0;
  }


  .introcontainer {
    position: relative;
    overflow: hidden;
    width: 100dvw;
    height: 100dvh;
    background-color: #1C1C1C;
  }

  .preview {
    position: absolute;
    overflow: hidden;
    width: 100dvw;
    height: 100dvh;
    background: #dddace;
    background-size: cover;
    background-position: center center;
  }

  .overlay{
    position: relative;
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(28,28,28, .86);
  }

  .intro{
    position: relative;
    font-family: 'ownersmedium'; 
    color: #DDDACA; 
    height: 100%;
    margin: 0 10vw 0 10vw; 
    font-size: 6.75rem;
    font-size: clamp( 40px, 6lvh, 150px );
    line-height: clamp(75px, 11dvh, 90px);
    letter-spacing: 0.015em;
    font-weight: 500;
  }

  .more {
    margin-top: 8dvh;
    position: absolute;
    font-family: 'ownersregular'; 
    color: #DDDACA; 
    font-size: 22.55px;
    font-size: clamp(1.5rem, 2.368vw + -0.485rem, 5.409rem);
    letter-spacing: .3dvw;
    left: 65dvw;
    top: 65dvh;
  }

  .contact {
    font-family: "ownersregular"; 
    color: #DDDACA; 
    font-size: 22.55px;
    font-size: clamp(1.225rem, 2.368vw + -0.485rem, 1.409rem);
    letter-spacing: .2dvw;
  }

  .container {
    position: relative;
    display: inline-block;
    max-width: 100dvw;
    height: 100%;
    background-color: #1C1C1C;
    top: 2vw;
  }

  img {
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: cover;
    width: 100%;
    aspect-ratio: 9/16;
    overflow: hidden;
  }

  .previewvid{
    position: relative;
    overflow: hidden;
    background: #dddace;
    background-size: cover;
    background-position: center center;
    height: 80vh;
    margin: -5vh 0 0 0;
    z-index: 0;
  }

  video{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .footer {
    text-align: center;
    display: flex;
    align-items:center;
    justify-content:center;
    position: relative;
    max-width: 100dvw;
    height: 15dvh;
    background-color: #1C1C1C;
    margin: auto;
  }
}